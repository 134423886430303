<template>
  <!-- Content Wrapper. Contains page content -->
  <div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>About the Company</h1>
          </div><!-- /.col -->
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">Home</li>
              <li class="breadcrumb-item active">About</li>
            </ol>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <div class="content">
      <div class="container-fluid">
        <div class="row">
          <!-- /.col-md-6 -->
          <div class="col-lg-6">
            <div class="card">
              <Loader v-if="loading" style="height: 100%"  />
              <div class="card-header">
                <h1 class="card-title"><b>Company Details</b></h1>
                <div class="card-tools">
                  <button v-show="user.role === 'Admin'" @click="editClient(client)" type="submit" class="btn btn-primary btn-xs" data-toggle="tooltip" title="Edit Company Details">
                    <i class="fa fa-edit"></i>
                  </button>
                </div>
                
              </div>
              <div class="card-body">
                <table width="100%">
                  <tr>
                    <td width="40%"><b>Client</b></td>
                    <td>
                      {{ this.client.name }}
                      <skeleton-loader-vue v-show="loading" type="rect" :rounded="true" :width="250" :height="20" animation="fade" />
                    </td>
                  </tr>
                  <tr>
                    <td><b>Address</b></td>
                    <td>
                      {{ this.client.address }}
                      <skeleton-loader-vue v-show="loading" type="rect" :rounded="true" :width="250" :height="20" animation="fade" />
                    </td>
                  </tr>
                  <tr>
                    <td><b>Email</b></td>
                    <td>
                      {{ this.client.email }}
                      <skeleton-loader-vue v-show="loading" type="rect" :rounded="true" :width="200" :height="20" animation="fade" />
                    </td>
                  </tr>
                  <tr>
                    <td><b>Application Name</b></td>
                    <td>
                      {{ this.client.appname }}
                      <skeleton-loader-vue v-show="loading" type="rect" :rounded="true" :width="250" :height="20" animation="fade" />
                    </td>
                  </tr>
                  <tr>
                    <td><b>Version</b></td>
                    <td>
                      {{ this.client.version }}
                      <skeleton-loader-vue v-show="loading" type="rect" :rounded="true" :width="60" :height="20" animation="fade" />
                    </td>
                  </tr>
                </table>               
              </div>
            </div>

          </div>
          <!-- /.col-md-6 -->
        </div>

        <!-- employee modal -->
        <div class="modal fade" data-backdrop="static" id="clientmodal" tabindex="-1" role="dialog" aria-labelledby="clientModalLabel1" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
            <div class="modal-content">
              <div class="modal-header bg-orange">
                <h1 class="card-title modal-title"><b>Edit Company</b></h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              

              <div class="modal-body">
                  <div class="card-body">
                    <div class="row">

                      <div class="col-sm-6 text-center mb-4">

                        <div v-show="!cropped">
                            <label for="inputName">Client Photo</label><span class="text-danger">*</span>
                            <vue-avatar
                              :width="260"
                              :height="260"
                              :rotation="rotation"
                              :borderRadius="borderRadius"
                              :scale="scale"
                              ref="vueavatar"
                              @vue-avatar-editor:image-ready="onImageReady"
                              >
                            </vue-avatar>
                            <label>Zoom : {{scale}}x<br><input type="range" min=1 max=3 step=0.02 v-model.number='scale' /></label>
                            <label>Rotation : {{rotation}}°<br><input type="range" min=0 max=360 step=1 v-model.number='rotation' /></label>
                            <br/>
                            <button class="btn btn-primary mr-2" v-on:click="uploadClicked()"><i class="fa fa-upload" aria-hidden="true"></i> Upload Photo</button>
                            <button class="btn btn-primary" v-on:click="cropClicked()" :disabled="!imageready"><i class="fa fa-crop" aria-hidden="true"></i> Crop Photo</button>
                        </div>

                        <div v-show="cropped">
                            <img class="img-thumbnail mb-3" :src="croppedImage" alt="" style="border-radius: 50%;" />
                            <button v-show="cropped" class="btn btn-primary mr-2" v-on:click="resetClicked()"><i class="fa fa-undo" aria-hidden="true"></i> Reset</button>
                            <button v-show="cropped" :disabled="!croppedImage||!cropped||!form_avatar.id" class="btn btn-success" v-on:click="saveClicked()"><i class="fa fa-save" aria-hidden="true"></i> Save Photo</button>
                        </div>

                      </div>

                      <div class="col-sm-6">
                        <form @submit.prevent="updateClient()" @keydown="form.onKeydown($event)" novalidate>

                        <div class="form-group" :class="{'has-error':$v.form.name.$error,'has-success':!$v.form.name.$invalid }">
                          <label for="inputName">Client Name</label><span class="text-danger">*</span>
                          <input @keydown.enter="focusNext" type="text"  v-model.lazy="$v.form.name.$model" class="form-control" id="inputName" aria-describedby="inputClientname" :class="{'is-invalid':$v.form.name.$error,'is-valid':!$v.form.name.$invalid }" placeholder="Client name" autocomplete="off" />
                          <div class="invalid-feedback">
                              <span class="help-block" v-if="!$v.form.name.required"><i class="fa fa-exclamation-circle"></i> Client name is required.</span>
                              <span class="help-block" v-if="!$v.form.name.minLength"><i class="fa fa-exclamation-circle"></i> Client name must have at least {{$v.form.name.$params.minLength.min}} characters.</span>
                              <span class="help-block" v-if="!$v.form.name.maxLength"><i class="fa fa-exclamation-circle"></i> Client name must not exeed to {{$v.form.name.$params.maxLength.max}} characters.</span>
                          </div>
                        </div>

                        <div class="form-group" :class="{'has-error':$v.form.address.$error,'has-success':!$v.form.address.$invalid }">
                          <label for="inputPosition">Address</label><span class="text-danger">*</span>
                          <input @keydown.enter="focusNext" type="text"  v-model.lazy="$v.form.address.$model" class="form-control" id="inputPosition" aria-describedby="inputAddress" :class="{'is-invalid':$v.form.address.$error,'is-valid':!$v.form.address.$invalid }" placeholder="Address" autocomplete="off" />
                          <div class="invalid-feedback">
                              <span class="help-block" v-if="!$v.form.address.required"><i class="fa fa-exclamation-circle"></i> Address is required.</span>
                              <span class="help-block" v-if="!$v.form.address.minLength"><i class="fa fa-exclamation-circle"></i> Address must have at least {{$v.form.address.$params.minLength.min}} characters.</span>
                              <span class="help-block" v-if="!$v.form.address.maxLength"><i class="fa fa-exclamation-circle"></i> Address must not exeed to {{$v.form.address.$params.maxLength.max}} characters.</span>
                          </div>
                        </div>

                        <div class="form-group" :class="{'has-error':$v.form.email.$error,'has-success':!$v.form.email.$invalid }">
                          <label for="inputPosition">Email</label><span class="text-danger">*</span>
                          <input @keydown.enter="focusNext" type="text"  v-model.lazy="$v.form.email.$model" class="form-control" id="inputEmail" aria-describedby="inputEmail" :class="{'is-invalid':$v.form.email.$error,'is-valid':!$v.form.email.$invalid }" placeholder="Email" autocomplete="off" />
                          <div class="invalid-feedback">
                              <span class="help-block" v-if="!$v.form.email.required"><i class="fa fa-exclamation-circle"></i> Email is required.</span>
                              <span class="help-block" v-if="!$v.form.email.minLength"><i class="fa fa-exclamation-circle"></i> Email must have at least {{$v.form.email.$params.minLength.min}} characters.</span>
                              <span class="help-block" v-if="!$v.form.email.maxLength"><i class="fa fa-exclamation-circle"></i> Email must not exeed to {{$v.form.email.$params.maxLength.max}} characters.</span>
                          </div>
                        </div>

                        <div class="form-group" :class="{'has-error':$v.form.appname.$error,'has-success':!$v.form.appname.$invalid }">
                          <label for="inputPosition">Application Name</label><span class="text-danger">*</span>
                          <input @keydown.enter="focusNext" type="text"  v-model.lazy="$v.form.appname.$model" class="form-control" id="inputApplicationName" aria-describedby="inputApplicationName" :class="{'is-invalid':$v.form.appname.$error,'is-valid':!$v.form.appname.$invalid }" placeholder="App name" autocomplete="off" />
                          <div class="invalid-feedback">
                              <span class="help-block" v-if="!$v.form.appname.required"><i class="fa fa-exclamation-circle"></i> Application Name is required.</span>
                              <span class="help-block" v-if="!$v.form.appname.minLength"><i class="fa fa-exclamation-circle"></i> Application Name must have at least {{$v.form.appname.$params.minLength.min}} characters.</span>
                              <span class="help-block" v-if="!$v.form.appname.maxLength"><i class="fa fa-exclamation-circle"></i> Application Name must not exeed to {{$v.form.appname.$params.maxLength.max}} characters.</span>
                          </div>
                        </div>

                        <div class="form-group" :class="{'has-error':$v.form.version.$error,'has-success':!$v.form.version.$invalid }">
                          <label for="inputPosition">Version</label><span class="text-danger">*</span>
                          <input @keydown.enter="focusNext" type="text"  v-model.lazy="$v.form.version.$model" class="form-control" id="inputVersion" aria-describedby="inputVersion" :class="{'is-invalid':$v.form.version.$error,'is-valid':!$v.form.version.$invalid }" placeholder="Email" autocomplete="off" />
                          <div class="invalid-feedback">
                              <span class="help-block" v-if="!$v.form.version.required"><i class="fa fa-exclamation-circle"></i> Version is required.</span>
                              <span class="help-block" v-if="!$v.form.version.minLength"><i class="fa fa-exclamation-circle"></i> Version must have at least {{$v.form.version.$params.minLength.min}} characters.</span>
                              <span class="help-block" v-if="!$v.form.version.maxLength"><i class="fa fa-exclamation-circle"></i> Version must not exeed to {{$v.form.version.$params.maxLength.max}} characters.</span>
                          </div>
                        </div>

                        <button type="button" class="btn btn-default mr-2" data-dismiss="modal">Close</button>&nbsp;
                        <button type="submit" :disabled="$v.$invalid" class="btn btn-info" ><i class="fa fa-chevron-circle-right"></i> Save Details</button>

                        </form>

                      </div>
                    </div>
                    


                  </div>
              
              </div>
              <div class="card-footer">
                <div class="row col-12 p-0 m-0">
                    <div class="col-8 p-0">
                      
                    </div>
                    <div class="text-right col-12 p-0">
                      
                      
                    </div>
                </div>
              </div>

              


            </div>
          </div>
        </div>
        <!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content -->
  </div>
  <!-- /.content-wrapper -->
</template>

<script>

import {mapGetters} from 'vuex'
import axios from 'axios'
import Form from 'vform'
import Loader from '@/components/Loader'
import {VueAvatar} from 'vue-avatar-editor-improved'
import { required, minLength, maxLength, sameAs, not } from 'vuelidate/lib/validators'
import VueSkeletonLoader from 'skeleton-loader-vue';

export default {
  name: 'About',

  data() {
    return {
      rotation: 0,
      scale: 1,
      borderRadius: 200,
      cropped: false,
      imageready: false,
      croppedImage: null,
      avatar: null,
      loading: false,
      programmer: 'Christopher Marcelino',
      monitors: {},
      client: {},
      form: new Form({
        id: '',
        name: '',
        address: '',
        email: '',
        appname: '',
        version: '',
      }),
      form_avatar: new Form({
        id: 1,
        avatar: null,
      })
    };
  },

  validations: {
    form: {
      name: { required,  minLength: minLength(2),  maxLength: maxLength(60) },
      address: { required,  minLength: minLength(2),  maxLength: maxLength(60) },
      email: { required,  minLength: minLength(2),  maxLength: maxLength(60) },
      address: { required,  minLength: minLength(2),  maxLength: maxLength(60) },
      appname: { required,  minLength: minLength(2),  maxLength: maxLength(15) },
      version: { required,  minLength: minLength(2),  maxLength: maxLength(60) },
    }
  },

  components: {
    Loader, VueAvatar, VueSkeletonLoader,
  },

  computed: {
    ...mapGetters(['user'])
  },

  methods: {

    uploadClicked(){
      document.getElementById('avatarEditorCanvas').click();
    },

    saveClicked: function saveClicked() {
      var img = this.$refs.vueavatar.getImageScaled();
      this.$refs.image.src = img.toDataURL();
    },

    cropClicked(){
      var img = this.$refs.vueavatar.getImageScaled()
      this.croppedImage = img.toDataURL()
      this.form_avatar.avatar = img.toDataURL()
      this.cropped = true
    },

    setScale:function(scale){
      this.scale = parseFloat(scale)
    },
        
    onImageReady: function onImageReady() {
        this.scale = parseFloat(1);
        this.rotation = parseFloat(0);
        this.imageready = true;
    },

    resetClicked(){
      this.cropped = false
    },

    saveClicked(){
        this.$Progress.start();
        this.form_avatar.put('api/client-avatar/1').then(()=>{
                this.$swal.fire({
                icon: 'success',
                title: 'Client photo updated'
            })
            this.$Progress.finish();
            this.cropped = false;
        }).catch(()=>{
                this.$swal.fire({
                icon: 'error',
                title: 'Photo updating failed'
            });
            this.$Progress.fail();
        });
    },

    editClient(client) {
      this.form.reset();
      this.form.clear();
      this.form.fill(client);
      $('#clientmodal').modal('show');
    },

    updateClient() {
      this.$v.$touch();
      this.$Progress.start();
      if(!this.$v.$invalid) {
        try {
          
          this.form.put('api/client/' + this.form.id)
          .then(()=>{
                this.$swal.fire({
                  icon: 'success',
                  title: 'Client updated'
                })
                this.$Progress.finish();
                this.loadData();
          }).catch(()=>{
                this.$swal.fire({
                  icon: 'error',
                  title: 'Client update failed'
                });
                this.$Progress.fail();
          })
        } catch (e) {
            this.error = e.response.data.message;
            this.$Progress.fail();
        }
      }
      this.$v.$reset();
    },

    loadData() {
      axios.get('api/client').then(({ data }) => (this.client = data));
    },

  },

  async mounted () {
    this.loading = true;
    this.$Progress.start();
    await axios.get('api/client').then(({ data }) => (this.client = data));
    this.$Progress.finish();
    this.loading = false;
  }

}
</script>

<style scoped>
.skeleton {
  width: 100% !important;
}
</style>